/*
 * 
 * show service request details
 * 
 * allows for action and next step
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector, useDispatch } from 'react-redux';
//import Accordion from '../components/Accordion';
//import {
//    //TextField,
//    //Collapse, Box,
//    //Paper,
//    Grid,
//    Backdrop,
//    CircularProgress,
//    //Card, FormGroup,
//    //CardContent,
//    //CardActions,
//    //InputLabel, Select, FormControl, MenuItem, Checkbox,
//    Typography,
//    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
//    //Switch, Tooltip, Divider
//} from '@mui/material';

import {
    //TextField,
    //Collapse, 
    Box,
    Button,
    Grid,
    Backdrop,
    CircularProgress,
    Stack,
    Card,
    //Card, FormGroup,
    CardContent,
    CardActions,
    IconButton, Avatar, CardHeader,

    //InputLabel, Select, FormControl, MenuItem, Checkbox,
    Typography,
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';

import {
    removeEmptyStringElements,
    getAutoCompletedata,
    NewPageId,
    UpdateData,
    Pk1SingelItem,
    FindCategoryIcon
} from '../utils/CommonGraphql';

import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import ProgressStepper from '../Setup/ProgressStepper';
//import { ProgressQuestions } from '../utils/UrgencyOptions';
//
import SRShowInformation from '../List/SRShowInformation';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import EventDataList from '../components/EventDataList';
import ShowAvatarMessage from '../components/ShowAvatarMessage';
import MaterialGallery from '../Gallery/MaterialGallery';
import ListModule from '../List/ListModule';
import ShowPreSelect from '../components/ShowPreSelect';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import Skeleton from '../components/Skeleton';
import DocumentList from '../List/DocumentList';
import SRStatusSummery from '../List/SRStatusSummery';
import { onUpdate, onCreatePk } from '../graphql/subscriptions';
import FeedBackBox from '../components/FeedBackBox';
import ClipboardCopy from '../components/ClipboardCopy';



//const HtmlToReactParser = require('html-to-react').Parser;

//const HTMLtoReact = (htmlInput) => {
//    var htmlToReactParser = new HtmlToReactParser();
//    var reactElement = htmlToReactParser.parse(htmlInput);
//    return reactElement;
//}

const UUid = NewPageId("SRDetail");

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));


//Date.prototype.stdTimezoneOffset = function () {
//    var jan = new Date(this.getFullYear(), 0, 1);
//    var jul = new Date(this.getFullYear(), 6, 1);
//    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
//}

//Date.prototype.isDstObserved = function () {
//    return this.getTimezoneOffset() < this.stdTimezoneOffset();
//}





export default function SimpleCard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const Auth = useSelector(state => state.auth);
    const [loading, SetLoading] = useState(false);
    const initialState = {

        DetailComponent: "AddMessage",
        DrawerTitle: "Add Message",
        NewNumber: UUid,
        AddPrivateMessage: [],
        UpdateNumber: 0,
        UpdateCategory: "Meassage",
        SREvent: {},
        EventData: [],
        ProgressList: [],
        PhotoService: [],
        ShowManagerApprovalButton: false,
        StatusSymbol: "RuleIcon",
        StatusHeading: "Status Bar",
        ShowManagerApproved: "",
        LastStep: [],
        EquipmentList: [],
        LocationList: [],
        DisableAll: !Auth,
        Refresh: true,
        ProgressWidth: 6,
        DisableAcceptButton: false
    }
    const [state, setState] = useState(initialState);



    useEffect(() => {
        console.log("SRDetails.props", props)

        let DisableAcceptButton = props.DisableAll;

        //refresh data if customer has not completed the call
        if (props.SREvent) {
            if (props.SREvent.status === "Initiated" && state.Refresh) {
                if (props.SREvent.ServiceRequest && props.SREvent.ServiceRequest.pk1) {
                    //get data from DB
                    GetFreshData(props.SREvent.ServiceRequest.pk1, props.SREvent.ServiceRequest.sk1);
                    //refresh only one time
                    setState(prevState => ({
                        ...prevState,
                        Refresh: false
                    }))
                }
            } else {
                PopulteUpdate(props.SREvent);
            }

            //if (props.SREvent.ManagerApprovalNeeded
            //    && props.SREvent.ManagerApprovalStatus === "Pending") {
            //    DisableAcceptButton = true;
            //}
        }

        // if (props.DisableAll) {
        setState(prevState => ({
            ...prevState,
            DisableAll: props.DisableAll,
            DisableAcceptButton: DisableAcceptButton
        }))
        //  }

        var today = new Date(); //UTC in lambda
        var localeDate = today.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }); // to get the full date string
        //var localeHour = today.toLocaleString('en-US', { timeZone: 'America/Mexico_City' }); //to get just the hour
        console.log("ddddd.localeDate", localeDate);

        //let NewActiveDate = new Date(localeDate.getTime() + (offset * 60 * 60000));
        //let currentTime = NewActiveDate.toISOString();

        // var todaynext = localeDate.toISOString();
        //  console.log("ddddd.todaynext", todaynext);

    }, [])

    const GetFreshData = async (pk, sk) => {

        let NewData2 = await Pk1SingelItem(pk, sk);
        let NewData = await NewData2;

        if (NewData) {
            let SREvent = JSON.parse(NewData.dataJSON);

            console.log("SRDetailsPkSk.NewData", SREvent)

            PopulteUpdate(SREvent);
        }
    }

    const PopulteUpdate = (SREvent) => {
        //
        //console.log("SRDetails.1.1propsSREvent", props.SREvent)
        //console.log("SRDetails.1.1SREvent", SREvent)
        let ShowManagerApprovalButton = false;
        let StatusSymbol = "RuleIcon";
        let StatusHeading = "Status Bar";
        let ShowManagerMessage = false;
        let ManagerApprovedBy = [];
        let Read = "";
        // let SREvent = props.SREvent;
        let AllowManagerApproval = false;
        let UpdatePk = "";
        let ShowProgressEdit = true;
        let ShowFeedBack = false;
        let ShowActionBox = true;
        let ShareLink = "";
        let ProgressWidth = 6;

        if (!SREvent.Read) {
            SREvent.Read = [];
        }

        var currentUnixTime = new Date();
        var currentTime = currentUnixTime.toISOString();


        //          else {
        if (SREvent.ManagerApprovalStatus &&
            SREvent.ManagerApprovedBy &&
            SREvent.ManagerApprovedBy.length > 0) {
            ShowManagerMessage = true;
        }
        // }

        if (SREvent && SREvent.Read) {
            let FindRead = SREvent.Read.findIndex(x => x.sk1 === UserData.key);
            if (FindRead === -1) {
                Read = {
                    sk1: UserData.key,
                    ph1: UserData.pk1,
                    date: currentTime,
                    field1: UserData.field1,
                    field2: UserData.field2,
                    avatar: UserData.avatar,
                }
                SREvent.Read = [...SREvent.Read, Read];
                UpdateDatabase(SREvent);
            }
        }

        if (SREvent) {
            if (UserData.CustomerType === "Company" && Auth && SREvent.status === "Completed") {
                ShowFeedBack = true;
                ProgressWidth = 12;
            }

            if (SREvent.FeedBack && UserData.CustomerType === "Contractor" && UserData.Level > 600 && Auth) {
                ShowFeedBack = true;
                ProgressWidth = 12;
            }
        }

        if (UserData.CustomerType === "Company" && UserData.Level > 100 && Auth) {
            AllowManagerApproval = true;
        }

        if (UserData.CustomerType === "Contractor"
            || UserData.CustomerType === "Sub") {

            if (Auth) {
                ShowProgressEdit = false;
            }

            if (SREvent.ManagerApprovalNeeded) {
                StatusSymbol = "PanToolIcon";
                StatusHeading = "Wait till work Approved";
                ShowProgressEdit = true;
                if (SREvent.ManagerApprovalStatus === "Approved") {
                    StatusSymbol = "ThumbUpIcon";
                    StatusHeading = "Approved - Proceed with work";
                    ShowProgressEdit = false;
                }
            }
        }

        if (SREvent.Progress && SREvent.Progress > 99) {
            ShowActionBox = false
        }

        if (SREvent.ActionButton && SREvent.ActionButton !== "") {
            ShareLink = SREvent.ActionButton;
        }

        if (SREvent && SREvent.ServiceRequest && SREvent.ServiceRequest.pk1) {
            UpdatePk = SREvent.ServiceRequest.pk1;
        }

        let PostId = SREvent.PostId;
        GetDatafromDB("PostId", PostId);
        //console.log("SRDetails.1.2SREvent", SREvent)
        setState(prevState => ({
            ...prevState,
            SREvent: SREvent,
            StatusSymbol: StatusSymbol,
            StatusHeading: StatusHeading,
            ThisPostId: PostId,
            ShowProgressEdit: ShowProgressEdit,
            ShowActionBox: ShowActionBox,
            UpdatePk: UpdatePk,
            ShowManagerApprovalButton: SREvent.ManagerApprovalNeeded,
            ShowManagerMessage: ShowManagerMessage,
            ManagerApprovedBy: ManagerApprovedBy,
            AllowManagerApproval: AllowManagerApproval,
            ShowFeedBack: ShowFeedBack,
            ShareLink: ShareLink,
            ProgressWidth: ProgressWidth,
            ...SREvent
        }))


    }

    useEffect(() => {
        let UpdatedData = {};
        let SREvent = {};
        let PostId = props.SREvent.PostId;
        if (props.SREvent && props.SREvent.pk1) {
            console.log("SRDetails.1.1state.UpdatePk", props.SREvent.pk1)
            const subscribe1 = API.graphql({
                query: onUpdate,
                variables: {
                    pk1: props.SREvent.pk1
                }
            })
                .subscribe({
                    next: newdata => {
                        UpdatedData = newdata.value.data.onUpdate.dataJSON;
                        SREvent = JSON.parse(UpdatedData)
                        console.log("SRDetails.Subscription.1.SRDetails", SREvent)

                        if (SREvent && SREvent.pk1 === props.SREvent.pk1 && SREvent.sk1 === props.SREvent.sk1) {
                            console.log("SRDetails.Subscription.1.equal")
                            PopulteUpdate(SREvent);
                        }

                    }
                })

            if (subscribe1) {
                return () => {
                    subscribe1.unsubscribe();
                }
            }
        }

    }, [props.SREvent]);

    useEffect(() => {

        if (state.ThisPostId !== "") {

            API.graphql({
                query: onCreatePk,
                variables: {
                    pk1: `Post::${state.ThisPostId}`
                }
            })
                .subscribe({
                    next: newdata => {
                        console.log("SRDetail.Subscription.PostId", state.ThisPostId)
                        GetDatafromDB("PostId", state.ThisPostId);
                    }
                })
        }

    }, [state.ThisPostId]);

    const GetDatafromDB = async (Category, cid) => {
        try {
            SetLoading(true);
            console.log("Category, cid", Category, cid)
            const DataString = {
                Category: Category,
                FindArray: [
                    {
                        ArrayName: "DataBack",
                        //DuplicateArray: "RawData",
                        ArraySearchString: ":",
                    }
                ]
            };

            let NewData = await getAutoCompletedata(DataString, cid);

            //group each type of PostId
            console.log("SRDetails.NewData", NewData)

            let Data = NewData.DataBack;

            let LocationList = [];
            let EquipmentList = [];
            let LastStep = [];
            if (Data && Data.length > 0) {
                Data.map(each => {
                    if (each.sk1 && each.sk1.includes("Location")) {
                        LocationList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Equipment")) {
                        EquipmentList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Post::Step")) {
                        LastStep.push(each)
                    }
                })
            }

            setState(prevState => ({
                ...prevState,
                LocationList: LocationList,
                EquipmentList: EquipmentList,
                LastStep: LastStep
            }))

            SetLoading(false);
        } catch (err) { console.log("SRDetails.GetDatafromDB.err", err) }
    }


    const CloseDrawer = () => {
        console.log("SRDetails.CloseDrawer")
        SetDeawerOpen(false)
    }

    const UpdateAsset = data => {
        console.log("SRDetails.1.UpdateAsset", data)
        if (data) {
            let PostId = props.SREvent.PostId;
            GetDatafromDB("PostId", PostId);
        }
    }

    const UpdateDatabase = (data) => {

        // props.UpdateSREvent(data);

        if (props.UpdateSREvent) {
            props.UpdateSREvent(data)
        }

        //var ValueToUpdate = {
        //    pk1: data.ServiceRequest.pk1,
        //    sk1: data.ServiceRequest.sk1,
        //    gsi1Pk2: data.gsi1Pk2,
        //    gsi2Pk2: data.gsi2Pk2,
        //    dataJSON: JSON.stringify(removeEmptyStringElements(data)),
        //};

        //console.log("SRDetails.1.pksk", ValueToUpdate)
        //console.log("SRDetails.1.datajson", data)

        //UpdateData(ValueToUpdate, "381-SRDetails")
        //    .then((up) => {
        //        console.log("SRDetails.Update.results", up)
        //        // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
        //    })
        //    .catch((err) => console.log("SRDetails.failed", err));
    }

    const UpdateEvent = data => {
        console.log("SRDetails.1.UpdateEvent", data)
        setState(prevState => ({
            ...prevState,
            SREvent: data,
            ...data
        }))

        UpdateDatabase(data);
    }

    const UpdateEvent2 = data => {
        console.log("SRDetails.1.UpdateEvent2", data)
        setState(prevState => ({
            ...prevState,
            SREvent: data,
        }))

        //if (props.UpdateSREvent) {
        //    props.UpdateSREvent(state.SREvent)
        //}

        //if (props.UpdateSREventFlag) {
        //    props.UpdateSREventFlag(true)
        //}

    }

    const AddMessage = (number, Category, Message = "") => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AddMessage",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    const AcceptDecline = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AcceptDecline",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    const ManagerApproval = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "ManagerApproved",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category,
        }))

        SetDeawerOpen(true)
    }


    const AdditemDone = () => {
        setState(prevState => ({
            ...prevState,
            TriggerAdd: false,
        }))
    }


    const KeyArray = (data, cat) => {
        console.log("KeyArray", data, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));
    }

    const handleChange = (event, name) => {
        //let change = event.target.checked;
        console.log("Switch", event.target.checked, name)
        setState({
            ...state,
            [name]: event.target.checked,
        });
        //props.handleInclude(change, props.step.key);
    };

    //const AddPrivateMessage = () => {

    //  let NewNumber = state.NewNumber + 1;
    //  let Newbox = {
    //    key: NewNumber,
    //    MessageJSON: {}
    //  }

    //  let AddingNewBox = [...state.AddPrivateMessage, Newbox];

    //  setState({
    //    ...state,
    //    AddPrivateMessage: AddingNewBox,
    //    NewNumber: NewNumber
    //  });
    //}

    //const SelectedValues = (e, key) => {
    //  let AddPrivateMessage = state.AddPrivateMessage;
    //  AddPrivateMessage[key] = e;
    //  setState({
    //    ...state,
    //    AddPrivateMessage: AddPrivateMessage
    //  });
    //}

    const ItemHistory = (item) => {

        setState({
            ...state,
            ItemData: item,
            DetailComponent: "ShowDetailsPkSk"
        });

        console.log("SRDetails.ItemHistory", item)

        SetDeawerOpen(true);

    }

    const AddBox = () => {

        setState(prevState => ({
            ...prevState,
            NextLoadModule: "Details",
        }));

        SetDeawerOpen(true);
    }

    const AddAsset = (Category) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AddAsset",
            AddCategory: Category
        }));

        SetDeawerOpen(true);
    }


    console.log("SRDetails.state", state)


    return (
        <div className={classes.paper}>

            <Grid container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
            >

                <Grid xs={12} item>
                    <Typography variant="h4" gutterBottom component="div">
                        {`Service: ${props.SREvent.title}`}
                    </Typography>
                </Grid>

                {state.ShowFeedBack ? (
                    <Grid xs={12} item>
                        <FeedBackBox
                            SREvent={state.SREvent}
                            CloseDrawer={() => props.CloseDrawer()}
                            UpdateEvent={data => UpdateEvent(data)}
                        />
                    </Grid>

                ) : (


                    <Grid xs={12} md={6} item >
                        <Grid container
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                        >

                            <Grid xs={12} item >
                                <Card>
                                    <CardHeader
                                        title={state.StatusHeading}
                                        avatar={
                                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                                {FindCategoryIcon(state.StatusSymbol)}
                                            </Avatar>
                                        }
                                    />
                                    <CardActions>
                                        <SRStatusSummery
                                            SR={state.SREvent}
                                        />
                                    </CardActions>
                                </Card>
                            </Grid>

                            {state.status !== "Canceled"
                                && state.ShowActionBox ? (
                                <Grid
                                    xs={12}
                                    // md={6}
                                    item >
                                    <Card key={"crd1"} >
                                        <CardHeader
                                            title="Action"
                                            //subheader="Information"

                                            avatar={
                                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                                    {FindCategoryIcon("decision")}
                                                </Avatar>
                                            }
                                        />

                                        <CardContent>
                                            <Stack
                                                direction={{ xs: 'column' }}
                                                spacing={2}
                                            >
                                                {UserData.CustomerType === "Company"
                                                    && UserData.Level > 190
                                                    && state.ManagerApprovalNeeded
                                                    && state.ManagerApprovalStatus !== "Approved"
                                                    ? (

                                                        <Button
                                                            onClick={() => ManagerApproval(state.EventData.length, "Manager", "Manager Action")}
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={!state.AllowManagerApproval}
                                                        >

                                                            Manager Approval
                                                        </Button>
                                                    ) : null}

                                                {UserData.CustomerType === "Company" ? (

                                                    <Button
                                                        onClick={() => AddMessage(state.EventData.length, "Canceled", "Cancel Service")}
                                                        variant="outlined" color="error"
                                                        disabled={state.DisableAll}
                                                    >
                                                        Cancel Service Call
                                                    </Button>
                                                ) : null}

                                                        {UserData.CustomerType === "Contractor"
                                                            && state.Quote === true ? (
                                                    <>
                                                        <Button
                                                            onClick={() => AddMessage(state.EventData.length, "Quote", "Sending Quote")}
                                                            variant="contained" color="secondary"
                                                            disabled={state.DisableAll}
                                                        >
                                                            Send Quote / Proposal
                                                        </Button>

                                                    </>
                                                ) : null}

                                                {UserData.CustomerType === "Sub"
                                                    && state.status !== "Accepted" ? (

                                                    <Button
                                                        onClick={() => AddMessage(state.EventData.length, "Accepted", "Accept Service Call")}
                                                        variant="contained" color="success"
                                                        disabled={state.DisableAcceptButton}
                                                    >
                                                        Accept Service
                                                    </Button>

                                                ) : null}

                                                {UserData.CustomerType === "Contractor"
                                                    && state.status !== "Accepted" ? (
                                                    <>
                                                        <Button
                                                            onClick={() => AddMessage(state.EventData.length, "Accepted", "Accept Service Call")}
                                                            variant="contained" color="success"
                                                            disabled={state.DisableAcceptButton}
                                                        >
                                                            Accept Service
                                                        </Button>


                                                        <Button
                                                            onClick={() => AddMessage(state.EventData.length, "Delegate", "Delegate Task")}
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={state.DisableAll}
                                                        >
                                                            Delegate
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Typography variant="h4" gutterBottom component="div">
                                                        {`Service: ${state.status}`}
                                                    </Typography>
                                                )}


                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ) : null}


                        </Grid>
                    </Grid>
                )}

                {UserData.CustomerType === "Contractor" && state.ProgressList ? (
                    <Grid xs={12} md={state.ProgressWidth} item >
                        <ProgressStepper
                            // Data={props.ProgressList}
                            SREvent={state.SREvent}
                            title="Progress Steps"
                            ShowUpDown={false}
                            ShowEdit={true}
                            ShowReset={false}
                            CurrentStep={null} //{props.SREvent.CurrentProgressStep}
                            Category="Progress"
                            disabled={state.ShowProgressEdit}
                            //SelectedDataReturn={(e) => KeyArray(e, "ProgressList")}
                            UpdateEvent={data => UpdateEvent(data)}
                            AddMessage={() => AddMessage(state.EventData.length, "Completed", "Work Completed")}
                            PerPage={5}
                        />
                    </Grid>
                ): null}

                <Grid xs={12} item>
                    <Card key={"crdgff1"}>
                        <CardHeader
                            title="Service Request Messages"
                            //subheader="Information"
                            action={
                                <IconButton
                                    aria-label="settings"
                                    disabled={state.DisableAll}
                                    onClick={() => AddMessage(state.EventData.length, "Message")}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("AddSMS")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            <EventDataList
                                SREvent={state.SREvent}
                                AddMessage={(number, Category) => AddMessage(number, Category)}
                                UpdateEvent={data => UpdateEvent(data)}
                            />
                        </CardContent>
                    </Card>
                </Grid>



                {state.PhotoService && state.PhotoService.length > 0 && (
                    <Grid xs={12} md={6} item >
                        <Card key={"creedsdfff1"} >
                            <CardHeader
                                title="Images / Video"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Image")}
                                    </Avatar>
                                }
                            />
                            <CardContent>

                                <MaterialGallery
                                    PhotoService={state.PhotoService}
                                />

                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {state.Documents && state.Documents.length > 0 && (
                    <Grid xs={12} md={6} item >
                        <DocumentList
                            DocumentList={state.Documents}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={6} >
                    <Card key={"creedsd1"} >
                        <CardHeader
                            title="Service Equipment"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Equipment")}
                                    disabled={state.DisableAll}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Equipment")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            {state.EquipmentList.length > 0 ? (
                                <ListModule
                                    DataBack={state.EquipmentList}
                                    title="Equipment List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={!state.DisableAll}
                                    ShowDetailLink={!state.DisableAll}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={true}
                                    disabled={state.DisableAll}
                                />
                            ) : (<Skeleton />)}

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Card key={"crkkjhd1"}>
                        <CardHeader
                            title="Service Location"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Location")}
                                    disabled={state.DisableAll}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Location")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            {state.LocationList.length > 0 ? (
                                <ListModule
                                    DataBack={state.LocationList}
                                    title="Location List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={!state.DisableAll}
                                    ShowDetailLink={!state.DisableAll}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={true}
                                    disabled={state.DisableAll}
                                />
                            ) : (<Skeleton />)}

                        </CardContent>
                    </Card>
                </Grid>


                {state.SpecialInstructions && state.SpecialInstructions !== "" ? (
                    <Grid item xs={12} >
                        <Card key={"crkkjhd1"}>
                            <CardHeader
                                title="Special Instructions"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("AssignmentIcon")}
                                    </Avatar>
                                }
                            />

                            <CardContent>
                                <Typography >
                                    {state.SpecialInstructions}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}

                {props.SREvent.posterId && (
                    <Grid item xs={12}>
                        <SRShowInformation
                            Data={props.SREvent.posterId}
                        />
                    </Grid>
                )}



                {props.SREvent.PreSelect && props.SREvent.PreSelect.length > 0 && (
                    <Grid item xs={12}>
                        <ShowPreSelect
                            disabled={state.DisableAll}
                            PreSelect={props.SREvent.PreSelect}
                        />
                    </Grid>
                )}

                {state.ShareLink && state.ShareLink !== "" ? (
                    <Grid item xs={12}>
                        <ClipboardCopy copyText={state.ShareLink} />
                    </Grid>
                ) : null}


            </Grid>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={props.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    SREvent: state.SREvent,
                    UpdateEvent: data => UpdateEvent(data),
                    UpdateAsset: data => UpdateAsset(data),
                    CurrentProgressStep: state.CurrentProgressStep,
                    UpdateNumber: state.UpdateNumber,
                    UpdateCategory: state.UpdateCategory,
                    Category: state.AddCategory,
                    LastStep: state.LastStep,
                    DrawerTitle: state.DrawerTitle,
                    ItemData: state.ItemData,
                    key: NewPageId("srdet"),
                    id: NewPageId("srdetil"),
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </div>
    );
}

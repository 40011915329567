import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Stack,
    Backdrop, CardContent,
    CircularProgress, Typography,
    Stepper, Step, StepContent, StepLabel,
    Snackbar, Card, Link
} from '@mui/material';
import { API, Auth } from "aws-amplify";
import {
    CreateContractorId,
} from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import { SendEmail } from '../utils/SendEmail';
import RenderModule from '../utils/RenderModule';
import { EnterSMS } from '../MainWebsite/CreateDBEntries';
import { useSelector } from 'react-redux';
import Calendly from '../components/calendly';
//import { RegistorNumber, PhoneNumberMessagingService, buyPhoneNumber } from '../Twilio/AvailablePhoneNumber';

var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();
var TimeStamp2 = currentUnixTime.getTime();
let TimeStamp = TimeStamp2 + (30 * 1000 * 60 * 60 * 24);
let ShowDate = currentUnixTime.toLocaleString();
const UUid = CreateContractorId("x");

//const AWS = require("aws-sdk");
//AWS.config.region = 'us-east-1'; // Region
//AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
//});

//const dynamodb = new AWS.DynamoDB.DocumentClient();

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

let DevData = {
    "key": "autdsfdgdtoi",
    "FormName": "Developer Edit",
    "FormType": "DevEdit",
    "Category": "DevEdit",
    "PhotoService": [],
    "Documents": [],
    "FormData": [
        {
            "Order": 3,
            "options": [],
            "label": "SubDomain",
            "VariableName": "SubDomain",
            "Editable": false,
            "TableColumn": false,
            "VariableInput": "ssuubbdomain.sellonline.com",
            "type": "textline",
            "key": "DevEdit_kf71hTZq34",
            "Category": "DevEdit",
            "required": true,
            "defaultValue": "ssuubbdomain.sellonline.com",
            "ShowValue": "ssuubbdomain.sellonline.com"
        },
        {
            "Order": 3,
            "options": [],
            "label": "S3 Bucket",
            "VariableName": "Bucket",
            "Editable": false,
            "TableColumn": false,
            "VariableInput": "news3bucket",
            "type": "textline",
            "key": "DevEdit_kf71hTZq35",
            "Category": "DevEdit",
            "required": true,
            "defaultValue": "news3bucket",
            "ShowValue": "news3bucket"
        },
        {
            "Order": 3,
            "options": [],
            "label": "Subscription Fee",
            "VariableName": "fee",
            "Editable": false,
            "TableColumn": false,
            "VariableInput": "0",
            "type": "textline",
            "key": "DevEdit_kf71hTZq36",
            "Category": "DevEdit",
            "required": true,
            "defaultValue": "1900",
            "ShowValue": "1900"
        },
        {
            "Order": 2,
            "options": [],
            "label": "Status",
            "VariableName": "status",
            "defaultValue": true,
            "Category": "DevEdit",
            "VariableInput": true,
            "type": "switch",
            "key": "DevEdit_kf71hTZq37",
            "required": false,
            "Searchable": true,
            "Editable": true,
            "TableColumn": false
        }
    ]
}

const Blocks = [

    {
        title: `Select Subscription`,
        message: ``,
        LoadComponenet: "SubscriptionPage",
        id: "SubscriptionPage",
        Category: "SubscriptionPage",
        ShowAdd: false,
        DetailComponent: "SubscriptionPage",
        FormData: [],
        ReturnArray: "ESCReturn",
        propss: {},
    },
    //{
    //    title: "Account Type",
    //    message: "",
    //    LoadComponenet: "AccountType",
    //    id: "AccountType",
    //    FormData: [],
    //    Category: "AccountType",
    //    ShowAdd: false,
    //    DetailComponent: "AccountType",
    //    ReturnArray: "AccountType",
    //    propss: {}
    //},    
    
    {
        title: "Account Setup",
        message: "",
        LoadComponenet: "AccountSetup",
        id: "AccountSetup",
        FormData: [],
        Category: "AccountSetup",
        ShowAdd: false,
        DetailComponent: "SMSAddEdit",
        ReturnArray: "StepReturn",
        propss: {}
    },

    {
        title: "Confirm",
        message: "",
        LoadComponenet: "ConfirmEmailText",
        id: "ConfirmEmailText",
        FormData: [],
        Category: "ConfirmEmailText",
        ShowAdd: false,
        DetailComponent: "ConfirmEmailText",
        ReturnArray: "ConfirmEmailText",
        propss: {}
    },
    {
        title: `Select Your New Text/SMS number`,
        message: ``,
        LoadComponenet: "AddTwilioNumber",
        id: "SignupWizEachStep",
        Category: "OwnerSignupSMS",
        ShowAdd: false,
        DetailComponent: "OwnerSignupSMS",
        FormData: [],
        ReturnArray: "ESCReturn",
        propss: {},
    },
    
   
];
//

export default function SimpleCard(props) {
    // const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [SendNotification, setSendNotification] = useState(true);
    const version = useSelector(state => state.version);
    // const [expanded, setExpanded] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        StepValueArray: Blocks,
        SignUpComplete: false,
        SignUpMessageHeading: "",
        SignUpMessage: "",
        terms: false,
        SelectedNumber: "",
        AccountType: "Individual",
        WebsiteType: window.WebsiteType,
        PersonInformation: {
            email: "ali_rehan@msn.com",
            mobile: "+15627601914"
        },
        SREvent: {
            ContractorData: {
                CompanyId: "Developer::Account::0786",
                ContractorId: "Developer::Account::0786",
                avatar: "",
                CompanyName: "Service Notification Inc",
            },
            RequestorSMS: "",
        }

    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                //let signupmessage = `Thanks you for signing up. Our team will contact you as soon as the setup process is complete.  The setup process ususally takes 24 to 48 hours.  Onces setup is complete, you can start handing out the Text Number (${state.SelectedNumber})`

                let FormatedNumber = formatPhoneNumber(state.SelectedNumber);

                let signupmessage = `Thanks you for signing up. Your new number (${FormatedNumber}) will be ready to use within an hour. Please use calendar below to setup the on-boarding call.  The link to schedule is also provided in the confirmation email.`

                setState(prevState => ({
                    ...prevState,
                    SignUpComplete: true,
                    SignUpMessageHeading: "Sign Up Complete",
                    SignUpMessage: signupmessage
                }))


            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    useEffect(() => {

        let usernamecode = process.env.REACT_APP_SIGNINUSER;
        usernamecode = usernamecode.toLocaleLowerCase();
        let PPw = process.env.REACT_APP_PPW;
        console.log("LoginPage.SignUp.SignIn", usernamecode, PPw);
        Auth.signIn(usernamecode, PPw).then(user => {
            console.log("LandingPage.user", user);
            //   LoadDataRedux();

        }).catch(err => {
            console.log("LandingPage.err", err);
            setState((prevState) => ({
                ...prevState,
                toDashboard: false,
                confirmcodemessage: `Sorry The Sign In failed. Please try again or contact ${state.ContractorName}`,
                step: "SignInEmail"
            }))
        })

        //  SendText(`SMS API test`);


    }, [])

    //useEffect(() => {

    //    let phoneNumberSid = RegistorNumber("+16617779091");
    //    console.log("Twilio.Registor", phoneNumberSid);

    //}, []);


    const handleNext = () => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //const handleExpandClick = () => {
    //    setExpanded(!expanded);
    //};


    const ClosingDrawer = (e) => {
        console.log("ClosingDrawer", e)
        SetDeawerOpen(false)
    }

    const FinalStep = () => {

        let AlarmMessage = "";
        let OpenAlert = false;

        //if (state.terms === false) {
        //    OpenAlert = true;
        //    AlarmMessage += " * Please review and accept Terms & Conditions ";
        //}

        if (state.SelectedNumber === "") {
            OpenAlert = true;
            AlarmMessage += " * Please Select the Text Number ";
        }

        if (Object.keys(state.PersonInformation).length === 0) {
            OpenAlert = true;
            AlarmMessage += " * Problem with Signup Informaiton ";
        }

        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: OpenAlert,
            }));

        } else {

            setState(prevState => ({
                ...prevState,
                DoneDone: true
            }));

            
            GetTwilioNumber();
            SaveData();

        }
    }

    const GetTwilioNumber = async () => {

        try {
            if (state.SelectedNumber !== "") {

                SetLoading(true)

                let WebsiteType = "ContractorCheckIn"
                if (window.WebsiteType === "ServiceWithText") {
                    WebsiteType = "ServiceWithText";
                }

                var SMSstring = {
                    "areaCode": "",
                    "CountryCode": "",
                    "phoneNumber": state.SelectedNumber,
                    "TwilioExecute": "Registor",
                    "WebsiteType": WebsiteType
                };

                console.log(
                    "GetTwilioNumber.STRING",
                    SMSstring
                );

                let LocalNumbers = await API.post("TwilioNumberList", "", {
                    body: { Records: SMSstring }
                });

                console.log(
                    "GetTwilioNumber.rESULT",
                    LocalNumbers
                );

                ReserveSMS(LocalNumbers);

                SetLoading(false)

            }
        } catch (err) {
            console.log("GetTwilioNumber.err", err)
        }
    }

    const ReserveSMS = (LocalNumbers) => {

        let CreateSMS = {
            UUID: UUid,
            SelectedNumber: state.SelectedNumber,
            OwnerId: state.PersonInformation.CompanyId,
            OwnerName: state.PersonInformation.CompanyName,
            date: currentTime,
            TimeStamp: TimeStamp,
            ShowDate: ShowDate,
            TimeZone: state.PersonInformation.TimeZone,
            PersonId: state.PersonInformation.sk1,
            PersonName: state.PersonInformation.field1,
            PersonMobile: state.PersonInformation.mobile,
            PersonEmail: state.PersonInformation.email,
            Title: state.PersonInformation.field1,
            PhoneSid: LocalNumbers,
            SubscriptionPlan: state.SubscriptionPlan,
            WebsiteType: state.WebsiteType,
            status: "Active",
            NewSubscription: true
        }

        EnterSMS(CreateSMS);

    }

    const SaveData = () => {

        try {

            let postHTML = `<div><p>${state.SignUpMessage}</p>`;

            postHTML += `<p><a href="https://app.${state.WebsiteType}.com/" target="_blank">
                            Log in </a> to the councel to setup your password and access the system.  
                        </p>`;
            
            //Calendar link
            postHTML += "<p>Book a 30-minute Call:</p>";
            postHTML += `<p><a href="https://calendly.com/servicewithtext/30min" target="_blank">
                            <img alt="Start" src="https://www.servicewithtext.com/images/calendery_small.png">
                        </a></p>`;

            //postHTML += "<p>System Introduction Video:</p>";
            //postHTML += `<p><a href="https://youtu.be/KpNdI5C036A" target="_blank">
            //                <img alt="Start" src="https://www.contractorcheckin.com/public/intro_video_still.png">
            //            </a></p>`;

            postHTML += "<p>Get Started - Easy step by step setup videos:</p>";

            if (state.WebsiteType === "ContractorCheckIn") {
                postHTML += `<p><a href="https://www.youtube.com/playlist?list=PLoTt7uES3155qw9Gw7y-ticjr0C-6ZUri" target="_blank">
                            <img alt="Start" src="https://www.contractorcheckin.com/public/first-time-signin.PNG">
                        </a></p>`;

                postHTML += "<p>System Introduction Video:</p>";
                postHTML += `<p><a href="https://youtu.be/IIVywMRL_tg" target="_blank">
                            <img alt="Start" src="https://www.contractorcheckin.com/public/intro_video_still.png">
                        </a></p>`;
            } 

            if (state.WebsiteType === "ServiceWithText") {
                postHTML += `<p><a href="https://www.youtube.com/playlist?list=PLoTt7uES3157gc8XDTPq109pzTR8-Uqm5" target="_blank">
                            <img alt="Start" src="https://www.contractorcheckin.com/public/first-time-signin.PNG">
                        </a></p>`;

                postHTML += "<p>System Introduction Video:</p>";
                postHTML += `<p><a href="https://youtu.be/KpNdI5C036A" target="_blank">
                            <img alt="Start" src="https://www.contractorcheckin.com/public/intro_video_still.png">
                        </a></p>`;
            } 

            let postTEXT = "";

            postHTML += `<table>`;

            if (state.AccountType !== "") {
                postHTML += `<tr align="left"><th >Account Type</th><th >${state.AccountType}</th></tr>`;
                postTEXT += `Account Type: ${state.AccountType} \n`;
            }
                    

            if (state.SelectedNumber !== "") {
                postHTML += `<tr align="left"><th >Text Number</th><th >${state.SelectedNumber}</th></tr>`;
                postTEXT += `Text Number: ${state.SelectedNumber} \n`;
            }


            let CreateTable = [

                {
                    label: "Name",
                    ver: "field1"
                },
                {
                    label: "Email",
                    ver: "email"
                },
                {
                    label: "Phone",
                    ver: "mobile"
                },
                {
                    label: "Company",
                    ver: "CompanyName"
                },
            ]

            if (state.PersonInformation) {

                CreateTable.map(EachForm => {

                    postHTML += `<tr align="left"><th >${EachForm.label}</th><th >${state.PersonInformation[EachForm.ver]}</th></tr>`;
                    postTEXT += `${EachForm.label}: ${state.PersonInformation[EachForm.ver]} \n`;

                })

            }

            postHTML += `<tr align="left"><th >Terms & Condition Acknolodged</th><th >${state.terms}</th></tr>`;
            // postTEXT += `Terms & Condition Acknolodged: ${state.terms} \n`;

            // postHTML += `<tr align="left"><th >Customer ID</th><th >${UUid}</th></tr>`;
            postHTML += "</table></div>";

            EmailNotification(postHTML, postTEXT);
            SendText(postTEXT);


        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const SendText = async postTEXT => {
        try {
            let SMSstring = {
                Body: `CheckIn SignUp \n${postTEXT}`,
                FromSMS: "+15627601914",
                From: "+15623936334",
                To: ["+15627601914"],
                mediaUrl: [],
                pk1: "",
                CompanyId: "",
                ContractorId: "",
                SMSList: [{
                    PersonName: "",
                    PersonId: "",
                    phone: "+15627601914"
                }]
            };

            API.post("SMStoTwilio", "", {
                body: { Records: SMSstring }
            }).then(response => {
                console.log("SendText.response", response)
            }).catch(err => console.log("SendText.err1", err))

        } catch (err) {
            console.log("SendText.err2", err)
        }

    }


    const EmailNotification = (postHTML, postTEXT) => {

        let title = `${state.WebsiteType}.com Sign-Up Complete`
        let PersonList = ["support@sninc.us", "ali.rehan.jci@gmail.com"]

        if (SendNotification) {
            PersonList.push(state.PersonInformation.email)
        }

        let ContractorSubDomain = `https://app.${state.WebsiteType}.com`;
        let DvLogo = "https://www.contractorcheckin.com/images/Logo_checkin_White.png"
        if (state.WebsiteType) {
            DvLogo = "https://www.servicewithtext.com/images/Logo_svc_White.png";
        }

        let SendEvent = {

            SpecialInstructions: "",
            PreSelect: [],
            title: title,
            postHTML: postHTML,
            postTEXT: postTEXT,
            PersonList: PersonList,
            ReplyEmail: "support@sninc.us",
            PostId: UUid,
            ContractorAddress: "30 N Gould St, Suite 37310, Sheridan, WY 82801",
            ContractorPhone: "562-760-1914",
            ContractorLogo: "",
            ContractorName: "Service Notification Inc",
            avatar: DvLogo,
            UnsubscribeLink: `${ContractorSubDomain}/Unsubscribe`,
            ActionButton: `${ContractorSubDomain}`,
            ActionButtonTitle: "Log In",
            ActionButtonShow: true
        }

        console.log("SubscriptionAdd1.SendEmail", SendEvent)

        SendEmail(SendEvent);

    }

    const SelectedDataReturn = (data) => {
        console.log("ContractorSignup.1data", data)
        let StepValueArray = state.StepValueArray;
        let Indexx = state.StepValueArray.findIndex(x => x.KeyId === data.KeyId);

        if (Indexx === -1) {
            Indexx = data.id;
        }

        StepValueArray[Indexx] = {
            ...StepValueArray[Indexx],
            ...data,
        };
        console.log("ContractorSignup.1after", StepValueArray);

        setState(prevState => ({
            ...prevState,
            StepValueArray: StepValueArray
        }))
    }


    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const SetValues = (data, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

    }

    console.log("ContractorSignup.state", state)
    console.log("ContractorSignup.SendNotification", SendNotification)
    console.log("ContractorSignup.Blocks", state.StepValueArray)

    return state.SignUpComplete ? (


        <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
        >
            <Typography variant="h1" gutterBottom component="div">
                {state.SignUpMessageHeading}
            </Typography>
            <Typography variant="h4" gutterBottom component="div">
                {state.SignUpMessage}
            </Typography>
            <Typography variant="h4" gutterBottom component="div">
                Thank you,
            </Typography>
            <Typography variant="h3" gutterBottom component="div">
                {`www.${state.WebsiteType}.com`}
            </Typography>
            <Typography variant="h1" gutterBottom component="div">
                Book a 30 Minute On Boarding Call
            </Typography>
            <Typography variant="h4" gutterBottom component="div">
                Our team can walk you through the system and help you get started
            </Typography>
            <Calendly 
                prefill={{
                    email: state.PersonInformation.email,
                    name: state.PersonInformation.field1,
                    smsReminderNumber: state.PersonInformation.mobile
                }}
            />
        </Stack>
    ) : (
        <>
            <Card >
                <CardContent>

                        <Stepper activeStep={activeStep} orientation="vertical">
                                                    
                        {state.StepValueArray
                            && state.StepValueArray.length > 0
                            && state.StepValueArray.map((block, index) => {

                                return (

                                    <Step key={index}>
                                        <StepLabel>{block.title}</StepLabel>
                                        <StepContent>

                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <Typography >{block.message}</Typography>
                                                </Grid>

                                                <Grid item xs={12}>

                                                    <RenderModule

                                                        LoadComponenet={block.LoadComponenet}
                                                        StepValueArray={state.StepValueArray}
                                                        Category={block.Category}
                                                        SREvent={state.SREvent}
                                                        // SaveDataBack={state.SaveDataBack[index]}
                                                        SelectedDataReturn={(data) => SelectedDataReturn(data)}
                                                        PersonInformation={(data) => SetValues(data, "PersonInformation")}
                                                        SelectedNumber={(data) => SetValues(data, "SelectedNumber")}
                                                        AccountType={(data) => SetValues(data, "AccountType")}
                                                        SubscriptionPlan={(data) => SetValues(data, "SubscriptionPlan")}
                                                      //  SubId={state.SubscriptionId}
                                                        WebsiteType={window.WebsiteType}
                                                        AccountTyp={state.AccountType}
                                                        PersonInfo={state.PersonInformation}
                                                        id={index}
                                                        activeStep={activeStep}
                                                        handleBack={() => handleBack()}
                                                        handleNext={() => handleNext(index)}
                                                        KeyId={block.id}
                                                        key={index + block.id}
                                                        OwnerSignup={true}
                                                        title={block.title}

                                                    />

                                                </Grid>

                                            </Grid>
                                        </StepContent>
                                    </Step>

                                )
                            })}

                        <Step key={100}>
                            <StepLabel>Save Details</StepLabel>
                            <StepContent>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <Typography >Upon sign-up completion, the system will send you email with the confirmation<Link onClick={() => setSendNotification(!SendNotification)} underline="none">
                                            .
                                        </Link></Typography>
                                    </Grid>

                                    <Grid item xs={12}>

                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color={SendNotification ? ("primary") : ("success")}
                                                onClick={() => FinalStep()}
                                            >
                                                Complete Signup
                                            </Button>

                                        </div>

                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>

                    </Stepper>

                </CardContent>
            </Card>

            <Typography variant="caption" display="block" gutterBottom>
                {version}
            </Typography>

            <Backdrop
                //  className={classes.backdrop}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => ClosingDrawer()}
                DrawerTitle="change this"
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: (e) => ClosingDrawer(e),
                    ...state.DetailsProps
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </>
    );
}

const initialstate = {
    auth: false,
    UserData: {},
    CompanyData: {},
    cid: {},
    Pref: [],
    HQData: {
        Bucket: "app.contractorcheckin.com",
        SubDomain: "app.contractorcheckin.com",
        avatar: "https://www.contractorcheckin.com/images/Logo_checkin_White.png",
        Logo: "https://www.contractorcheckin.com/images/Logo_checkin_Black.png"
    },
    ContractorData: {},
    DeveloperData: {
        ContractorId: "Developer::Account::0786",
        CompanyId: "Developer::Account::0786",
        CustomerType: "Developer",
        ContractorName: "Developer",
        CompanyName: "Developer",
        field1: "Developer",
        field2: "",
        Phone: "800-555-1212",
        Mobile: "+15623936334",
        SearchWords: "Developer",
        pk1: "Developer::Account::0786",
        key: "Developer::Account::0786",
        id: "8cad9b703"
    },
    NewServicePolicy: {
        key: "",
    },
    FormsPref: {},
    SingleServiceData: {},
    MenuHeader: "Main",
    ServiceLandingPage: false,
    NowEditing: "Company",
    CurrentEdit: {},
    TriggerUpdate: false,
    FormsDefault: {},
    FormData: {},
    Global: [],
    CurrentCompany: {},
    AccountAccess: {},
    ShowHelp: false,
    WebsiteType: "CheckIn",
    version: "10052024"
}



const RootRedux = (state = initialstate, { type, payload }) => {
    //
    let NewState = state;
   console.log("ReduxState.payload", type, payload)
    switch (type) {
        case 'LOGGED_IN':
            NewState = { ...state, auth: payload }
            break;
        case 'WEBSITE_TYPE':
            NewState = { ...state, WebsiteType: payload }
            break;
        case 'ACCOUNT_ACCESS':
            NewState = { ...state, AccountAccess: payload }
            break;       
        case 'LOGGED_OUT':
            NewState = { ...state, auth: payload }
            break;
        case 'USER_DATA':
            NewState = { ...state, UserData: payload }
            break;
        case 'CONTRACTOR_DATA':
            NewState = { ...state, ContractorData: payload }
            break;
        case 'HQ_DATA':
            NewState = { ...state, HQData: payload }
            break;
        case 'PREF_DATA':
            NewState = { ...state, Pref: payload }
            break;
        case 'SET_CID':
            NewState = { ...state, cid: payload }
            break;
        case 'NOW_EDITING':
            NewState = { ...state, NowEditing: payload }
            break;
        case 'SERVICE_LANDING_PAGE':
            NewState = { ...state, ServiceLandingPage: payload }
            break;
        case 'SINGLE_SERVICE_DATA':
            NewState = { ...state, SingleServiceData: payload }
            break;
        case 'TRIGGER_UPDATE':
            NewState = { ...state, TriggerUpdate: payload }
            break;
        case 'NEW_SVC_POLICY':
            NewState = { ...state, NewServicePolicy: payload }
            break;
        case 'FORMS_PREF':
            NewState = { ...state, FormsPref: payload }
            break;
        case 'FORMS_DEFAULT':
            NewState = { ...state, FormsDefault: payload }
            break;
        case 'FORMS_DATA':
            NewState = { ...state, FormData: payload }
            break; 
        case 'CURRENT_COMPANY':
            NewState = { ...state, CurrentCompany: payload }
            break; 
        case 'CURRENT_EDIT':
            NewState = { ...state, CurrentEdit: payload } 
            break; 
        case 'SHOW_HELP':
            NewState = { ...state, ShowHelp: payload } 
            break; 
        case 'MENU_HEADER':
            NewState = { ...state, MenuHeader: payload }
            break;
        case 'GLOBAL':
            NewState = { ...state, Global: payload }
            break;
        default:
            NewState = state;
    }

    console.log("Testing.Form.Edit.1.Redux.return", NewState)    

    return NewState;

}

export default RootRedux;
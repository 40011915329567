import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    TextField,
    Button, Stepper, Step, StepContent, StepLabel,
    //Grid,
    //Card,
    //CardContent,
    //Typography,
    Snackbar,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { NewPageId } from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
//import MuiAlert from '@mui/material/Alert';
//import ESCContractorStandAlone from '../Setup/ESCContractorStandAlone';

//function Alert(props) {
//    return <MuiAlert elevation={6} variant="filled" {...props} />;
//}

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

//key={indd} value={`${o.value}||${o.label}`

let Options = [
    {
        key: 4,
        Category: "Details",
        label: "Information / Details"
    },
    {
        key: 8,
        Category: "YesNo",
        label: "Yes / No Answer"
    },
    {
        key: 1,
        Category: "Assets",
        label: "Assets, Equipment, Location, etc"
    },
    {
        key: 2,
        Category: "Location",
        label: "Enter Location"
    },
    {
        key: 2,
        Category: "MarkLocation",
        label: "Mark GPS Location"
    },
    {
        key: 3,
        Category: "Images",
        label: "Pictures"
    },
    {
        key: 3,
        Category: "Documents",
        label: "Documents/Reports"
    },
    {
        key: 3,
        Category: "Emergency",
        label: "Emergency Service"
    },

]

let CheckInOptions = [
    {
        key: 4,
        Category: "Details",
        label: "Information / Details"
    },
    {
        key: 8,
        Category: "YesNo",
        label: "Yes / No Answer"
    },
    {
        key: 1,
        Category: "Assets",
        label: "Assets, Equipment, Location, etc"
    },
    {
        key: 2,
        Category: "Location",
        label: "Request Location"
    },
    {
        key: 3,
        Category: "Images",
        label: "Pictures"
    },
    {
        key: 3,
        Category: "Documents",
        label: "Documents/Reports"
    },


]


export default function SimpleCard(props) {
    const classes = useStyles();
    //const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    //const [expanded, setExpanded] = React.useState(false);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const [activeStep, setActiveStep] = useState(0);
    const initialState = {
        title: "",
        message: "",
        key: "",
        include: false,
        AlarmMessage: "",
        OpenAlert: false,
        Category: "",
        Keywords: "",
        field1: "",
        Question: "",
        words: "",
        ESC: [],
        Options: CheckInOptions,
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log("SMSAddEdit.props", props)

         let Optionss = CheckInOptions;

        if (WebsiteType === "ServiceWithText") {
            Optionss = Options;
        }

        setState(prevState => ({
            ...prevState,
            Options: Optionss
        }));

        if (props && props.step) {

            setState(prevState => ({
                ...prevState,
                ...props.step,
                Question: props.step.field1
            }));
        }
    }, [])

    const handleClose = (event, reason) => {
        // handle close of Alert

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const handleNext = (verr) => {

        let AlarmMessage = "";
        let OpenAlert = false;

        if (state[verr] === "") {
            OpenAlert = true;
            AlarmMessage += ` * Please enter ${verr} `;
        }

        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: OpenAlert
            }));

        } else {

            setActiveStep((prevActiveStep) => prevActiveStep + 1);

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const Updatedata = () => {

        let field1 = state.Question;
        if (state.Category === "YesNo") {
            field1 = `${field1}. Please answer with yes/no`;
        }

        // if (state.field1 !== "") {
        let NewStep = props.step;
        NewStep.field1 = field1;
        NewStep.label = field1;
        NewStep.Question = field1;
        NewStep.key = NewPageId(state.Category);
        NewStep.Category = state.Category;
        NewStep.keywords.words = state.words + ",";
        NewStep.keywords.ESC = state.ESC;
        console.log("SMSAddEdit.NewStep", NewStep);
        if (props.handleInclude) {
            props.handleInclude(NewStep)
        }

        if (props.CloseDrawer) {
            props.CloseDrawer();
        }

        //} else {
        //    setState(prevState => ({
        //        ...prevState,
        //        AlarmMessage: "Please enter question",
        //        OpenAlert: true
        //    }));
        //}

    }


    const SelectedValues = (item, name) => {

        setState({
            ...state,
            [name]: item.target.value,
        });

    };

    const ESCReturn = data => {
        console.log("SelectStepBox.ESCReturn", data);
        setState(prevState => ({
            ...prevState,
            ESC: data
        }))
    }

    console.log("SMSAddEdit.state", state);
    return (
        <>

            <Stepper activeStep={activeStep} orientation="vertical">

                <Step key={10}>
                    <StepLabel>Question / Instruction</StepLabel>
                    <StepContent>

                        <TextField
                            required={true}
                            label="Question"
                            name="Question"
                            value={state.Question || ""}
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            onChange={(e) => SelectedValues(e, "Question")}
                        />

                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext("Question")}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>

                <Step key={0}>
                    <StepLabel>Category</StepLabel>
                    <StepContent>

                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                            <InputLabel id="demo-simple-select-outlined-label">Question Category</InputLabel>
                            <Select
                                //labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                defaultValue={state.Category}
                                // error={ErrorMessage}
                                // required={Item.required}
                                //multiple
                                disabled={state.disabled}
                                autoWidth={true}
                                value={state.Category || ""}
                                onChange={(e) => SelectedValues(e, "Category")}
                                label="Question Category"
                            >
                                {state.Options.map((o, indd) => (
                                    <MenuItem key={indd} value={o.Category}>
                                        {o.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl >


                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext("Category")}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>

                {/* 
                <Step key={1}>
                    <StepLabel>KeyWords (optional)</StepLabel>
                    <StepContent>
                        <Typography>
                            Seperate each keyword with a comma
                        </Typography>

                        <TextField
                            label="Keywords"
                            name="Keywords"
                            value={state.words || ""}
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            onChange={(e) => SelectedValues(e, "words")}
                        />

                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext()}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>

                <Step key={2}>
                    <StepLabel>Notifification if Keywords found (optional)</StepLabel>
                    <StepContent>

                        <ESCContractorStandAlone
                            CompanyId={props.CompanyId}
                            showcustom={false}
                            Defaults={state.ESC}
                            CompanyName={props.CompanyName}
                            SelectedDataReturn={(e) => ESCReturn(e)}
                            showcustom={false}
                        />

                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext()}
                                    className={classes.button}
                                >
                                    {'Next'}
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>
                */}


                <Step key={3}>
                    <StepLabel>Save Data</StepLabel>
                    <StepContent>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => Updatedata()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>

                    </StepContent>
                </Step>
            </Stepper>
            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
            {/*              ;;
        <Alert onClose={handleClose} severity="warning">
          {state.AlarmMessage}
        </Alert>
      </Snackbar>
          */}

        </>
    );
}
